import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import _ from 'lodash';
import { database } from '@volenday/sdk';

const HistoryMenu = () => {
	const [history, setHistory] = useState({});

	const arrangement = [
		'Winners and Finalists',
		'Winners',
		'Finalists',
		'Photo Coverage',
		'Finalists Media Launch',
		'Kick-off Media Launch',
		'Media Launch',
		'Teaser',
		'Same Day Edit',
		'Highlight'
	];

	useEffect(() => {
		(async () => {
			const { data: categories } = await database
				.get({ entity: 'NewHistoryCategories' })
				.all()
				.fields(['Name', 'NewHistory', 'Type'])
				.exec();

			if (categories.length) {
				let history = _.chain(categories.map(d => d.NewHistory))
					.map('Year')
					.uniq()
					.value()
					.sort()
					.map(i => ({ Year: i, Categories: [] }));
				history = _.keyBy(history, 'Year');
				categories.map(d => history[d.NewHistory.Year].Categories.push(d));
				Object.keys(history).map(key => (history[key].Categories = _.keyBy(history[key].Categories, 'Type')));

				setHistory(history);

				$('[data-submenu]').submenupicker();
				$('li.dropdown, li.dropdown-submenu').hover(
					function() {
						$(this)
							.children('ul.dropdown-menu')
							.stop(true, true)
							.fadeIn();
					},
					function() {
						$(this)
							.children('ul.dropdown-menu')
							.stop(true, true)
							.fadeOut();
					}
				);
			}
		})();
	}, []);

	if (Object.keys(history).length === 0) return null;

	return (
		<>
			<a
				href="/history"
				tabIndex="0"
				class="dropdown-toggle"
				data-toggle="dropdown"
				role="button"
				aria-haspopup="true"
				aria-expanded="false"
				data-submenu>
				HISTORY <i class="fa fa-angle-double-right" aria-hidden="true" data-submenu />
			</a>
			<ul class="dropdown-menu">
				{Object.keys(history)
					.reverse()
					.map(key => {
						return (
							<li key={key} class="dropdown-submenu">
								<a
									href="#"
									tabIndex="0"
									class="dropdown-toggle"
									role="button"
									aria-haspopup="true"
									aria-expanded="false">
									Asia CEO Awards {key}
								</a>

								<ul class="dropdown-menu">
									{arrangement.map((a, i) => {
										if (history[key].Categories[a]) {
											return (
												<li key={i}>
													<a
														tabIndex="0"
														href={`/newHistory/${history[key].Categories[a].Type.replace(
															/\s/g,
															'_'
														)}/${key}`}>
														{history[key].Categories[a].Name}
													</a>
												</li>
											);
										} else {
											return null;
										}
									})}
								</ul>
							</li>
						);
					})}
			</ul>
		</>
	);
};

render(<HistoryMenu />, document.getElementById('HistoryMenu'));

const Footer = () => {
	const [footer, setFooter] = useState('');

	useEffect(() => {
		(async () => {
			const { data: footer } = await database
				.get({ entity: 'WebsitePartials' })
				.filter({ Part: 'Footer' })
				.exec();

			if (footer.length !== 0) setFooter(footer[0].Content);
		})();
	}, []);

	return (
		<div class="container">
			<div class="row">
				<div class="col-md-8 col-sm-8 col-xs-12 left">
					<div>{footer && <div dangerouslySetInnerHTML={{ __html: footer }} />}</div>
				</div>

				<div class="col-md-4 col-sm-4 col-xs-12 right">
					<div class="footerMenu">
						<a href="/Contact-Us">Contact Us</a>
						<a href="/Sitemap">Site Map</a>
						<a href="/Privacy-Policy">Privacy Policy</a>
					</div>
					Powered by{' '}
					<a href="http://www.volenday.com/" target="_blank">
						Volenday
					</a>
					.<br />
					Copyright {new Date().getFullYear()} &copy;
					<br />
					<a href="http://www.chalre.com/" target="_blank">
						Chalre Consultancy Services
					</a>
					.
				</div>
			</div>
		</div>
	);
};

render(<Footer />, document.getElementById('Footer'));

/**
 * TOASTR
 */
toastr.options.timeOut = 5000;
toastr.options.preventDuplicates = true;

/**
 * CAROUSEL
 */
function initializeCarousel() {
	$('.owl-carousel').owlCarousel('destroy');
	$('.owl-carousel').owlCarousel({
		loop: true,
		autoplay: true,
		autoplayTimeout: 3000,
		lazyLoad: true,
		responsiveClass: true,
		itemsDesktop: true,
		itemsDesktopSmall: true,
		itemsTablet: true,
		itemsTabletSmall: true,
		itemsMobile: true,
		responsive: {
			0: { items: 1, center: true, margin: 20 },
			600: { items: 2, margin: 20 },
			1000: { items: 4, margin: 30 }
		}
	});
}

$(document).ready(function() {
	initializeCarousel();
	$(window).focus(function() {
		$('.owl-carousel').owlCarousel('destroy');
		initializeCarousel();
	});
});
