const functions = {
    formMeta: function (formElement) {
        return {
            data: formElement.serialize(),
            action: formElement.attr('action'),
            method: formElement.attr('method'),
            submitButton: formElement.find(":submit"),
            resetButton: formElement.find(":reset")
        };
    },

    formToggle: function (form, type) {
        switch (type) {
            case 'enable':
                var elements = form[0].elements,
                    len = elements.length;
                for (var i = 0; i < len; ++i) {
                    elements[i].disabled = false;
                }
                break;

            case 'disable':
                var elements = form[0].elements,
                    len = elements.length;
                for (var i = 0; i < len; ++i) {
                    elements[i].disabled = true;
                }
                break;
        }
    }
};

module.exports = functions;