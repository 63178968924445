import { Subscriptions } from '../../entities';
import { database } from '@volenday/sdk';

$('#subscription-form').on('submit', async e => {
	if (e.isDefaultPrevented()) {
		// handle the invalid form...
	} else {
		var data = $('#subscription-form').serialize();
		$('input[type=submit]')
			.val('Submitting')
			.prop('disabled');

		try {
			database
				.create({ entity: Subscriptions })
				.data(data)
				.exec();

			$('#emailError').html('');
			$('#subscriptionMessage').html('Thank you for your subscription!');
			$('#subscription-form')[0].reset();

			$('input[type=submit]')
				.val('Submit')
				.removeProp('disabled');
		} catch (error) {
			$('#subscriptionMessage').html('');
			$('#emailError').html('Email is already subscribed.');
		}
	}

	e.preventDefault();
});
