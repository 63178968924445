import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Form, Input, message, Radio, Spin, Upload } from 'antd';
import { size } from 'lodash';
import prettyBytes from 'pretty-bytes';
import { database } from '@volenday/sdk';

import { Nominations, NominationCategories } from '../../entities';

const Nomination = () => {
	const [categories, setCategories] = useState([]);
	const [categoriesLoading, setCategoriesLoading] = useState(true);
	const [selectedCategory, setSelectedCategory] = useState({});
	const [criteria, setCriteria] = useState([]);
	const [criteriaLoading, setCriteriaLoading] = useState(false);

	const validationSchema = yup.object().shape({
		NominatorCompanyLogo: yup
			.mixed()
			.required('Nominator Company Logo is a required field')
			.test(
				'Nominator Company Logo',
				'Nominator Company Logo must be smaller than 2MB!',
				e => !e || e.size / 1024 < logoFileSizeLimit || false
			),
		NominatorName: yup.string().required('Nominator Name is a required field'),
		NominatorTitle: yup.string().required('Nominator Title is a required field'),
		NominatorOrganization: yup.string().required('Nominator Organization is a required field'),
		NominatorEmail: yup
			.string()
			.email()
			.required('Nominator Email is a required field'),
		CandidateName: yup.string().required('Candidate Name is a required field'),
		CandidateTitle: yup.string().required('Candidate Title is a required field'),
		CandidateOrganization: yup.string().required('Candidate Organization is a required field'),
		CandidateTelephone: yup.string().required('Candidate Telephone is a required field'),
		CandidateEmail: yup
			.string()
			.email()
			.required('Candidate Email is a required field'),
		AdditionalInfo: yup.string().max(5000, 'Additional Information should not be greater than ${max} characters'),
		SupportingDocuments: yup.array().max(10, 'You have reached maximum ${max} of ${max} supporting documents'),
		Category: yup.string().required('Category is a required field'),
		Criteria1: yup.string().when('Category', {
			is: e => e && criteria.length >= 1,
			then: yup
				.string()
				.max(
					5000,
					`${criteria[0] ? criteria[0].Name : 'Criteria 1'} should not be greater than \${max} characters`
				)
				.required(`${criteria[0] ? criteria[0].Name : 'Criteria 1'} is a required field`),
			otherwise: yup.string()
		}),
		Criteria2: yup.string().when('Category', {
			is: e => e && criteria.length >= 2,
			then: yup
				.string()
				.max(
					5000,
					`${criteria[1] ? criteria[1].Name : 'Criteria 2'} should not be greater than \${max} characters`
				)
				.required(`${criteria[1] ? criteria[1].Name : 'Criteria 2'} is a required field`),
			otherwise: yup.string()
		}),
		Criteria3: yup.string().when('Category', {
			is: e => e && criteria.length >= 3,
			then: yup
				.string()
				.max(
					5000,
					`${criteria[2] ? criteria[2].Name : 'Criteria 3'} should not be greater than \${max} characters`
				)
				.required(`${criteria[2] ? criteria[2].Name : 'Criteria 3'} is a required field`),
			otherwise: yup.string()
		}),
		Criteria4: yup.string().when('Category', {
			is: e => e && criteria.length >= 4,
			then: yup
				.string()
				.max(
					5000,
					`${criteria[3] ? criteria[3].Name : 'Criteria 4'} should not be greater than \${max} characters`
				)
				.required(`${criteria[3] ? criteria[3].Name : 'Criteria 4'} is a required field`),
			otherwise: yup.string()
		}),
		Criteria5: yup.string().when('Category', {
			is: e => e && criteria.length >= 5,
			then: yup
				.string()
				.max(
					5000,
					`${criteria[4] ? criteria[4].Name : 'Criteria 5'} should not be greater than \${max} characters`
				)
				.required(`${criteria[4] ? criteria[4].Name : 'Criteria 5'} is a required field`),
			otherwise: yup.string()
		}),
		Criteria6: yup.string().when('Category', {
			is: e => e && criteria.length >= 6,
			then: yup
				.string()
				.max(
					5000,
					`${criteria[5] ? criteria[5].Name : 'Criteria 6'} should not be greater than \${max} characters`
				)
				.required(`${criteria[5] ? criteria[5].Name : 'Criteria 6'} is a required field`),
			otherwise: yup.string()
		})
	});

	const formik = useFormik({
		initialValues: {},
		validationSchema,
		onSubmit: async values => {
			try {
				await database
					.create({ entity: Nominations })
					.data(values)
					.exec();

				message.success('Nomination successfully submitted.');
				$('html, body').animate(
					{
						scrollTop: '0px'
					},
					500
				);

				formik.resetForm();
			} catch (error) {
				message.error('Something went wrong. Please try again.');
			}
		}
	});

	const getNominationCategories = async () => {
		const categories = await database
			.get({ entity: NominationCategories })
			.all()
			.fields(['Award', 'Description', 'Name'])
			.sort({ Name: 1 })
			.exec();
		setCategories(categories.data);
		setCategoriesLoading(false);
	};

	useEffect(() => {
		(async () => {
			await getNominationCategories();
		})();
	}, []);

	const handleCategoryChange = async value => {
		setCriteriaLoading(true);
		const { data } = await database
			.get({ entity: 'NominationCriteria2' })
			.fields(['Name', 'Description'])
			.filter({ Category: value })
			.sort({ DateCreated: 1 })
			.exec();
		setCriteria(data);
		setCriteriaLoading(false);
	};

	const formItemLayout = {
		colon: false,
		labelAlign: 'left',
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 }
		},
		layout: 'horizontal',
		onSubmit: formik.handleSubmit,
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 }
		}
	};

	return (
		<Form {...formItemLayout}>
			<div class="form-section">
				<h3 class="pageTitle">NOMINATOR INFORMATION</h3>
				<p>Please give us information about you</p>
				<Form.Item
					label={`Company Logo (max: ${logoFileSizeLimit / 1000}mb)`}
					hasFeedback
					help={formik.errors.NominatorCompanyLogo}
					required={true}
					validateStatus={
						size(formik.errors) ? (formik.errors.NominatorCompanyLogo ? 'error' : 'success') : null
					}>
					<Upload
						beforeUpload={() => false}
						disabled={formik.isSubmitting}
						id="NominatorCompanyLogo"
						fileList={formik.values.NominatorCompanyLogoFile ? formik.values.NominatorCompanyLogoFile : []}
						name="NominatorCompanyLogo"
						onChange={e => {
							if (e.file) {
								formik.setFieldValue('NominatorCompanyLogoFile', [e.file]);
								formik.setFieldValue('NominatorCompanyLogo', e.file);
							}
						}}>
						<Button>Click to Upload</Button>
					</Upload>
				</Form.Item>
				<Form.Item
					label="Name"
					hasFeedback
					help={formik.errors.NominatorName}
					required={true}
					validateStatus={size(formik.errors) ? (formik.errors.NominatorName ? 'error' : 'success') : null}>
					<Input
						disabled={formik.isSubmitting}
						id="NominatorName"
						name="NominatorName"
						onChange={formik.handleChange}
						type="text"
						value={formik.values.NominatorName}
					/>
				</Form.Item>
				<Form.Item
					label="Title"
					hasFeedback
					help={formik.errors.NominatorTitle}
					required={true}
					validateStatus={size(formik.errors) ? (formik.errors.NominatorTitle ? 'error' : 'success') : null}>
					<Input
						disabled={formik.isSubmitting}
						id="NominatorTitle"
						name="NominatorTitle"
						onChange={formik.handleChange}
						type="text"
						value={formik.values.NominatorTitle}
					/>
				</Form.Item>
				<Form.Item
					label="Organization"
					hasFeedback
					help={formik.errors.NominatorOrganization}
					required={true}
					validateStatus={
						size(formik.errors) ? (formik.errors.NominatorOrganization ? 'error' : 'success') : null
					}>
					<Input
						disabled={formik.isSubmitting}
						id="NominatorOrganization"
						name="NominatorOrganization"
						onChange={formik.handleChange}
						type="text"
						value={formik.values.NominatorOrganization}
					/>
				</Form.Item>
				<Form.Item
					label="Email"
					hasFeedback
					help={formik.errors.NominatorEmail}
					required={true}
					validateStatus={size(formik.errors) ? (formik.errors.NominatorEmail ? 'error' : 'success') : null}>
					<Input
						disabled={formik.isSubmitting}
						id="NominatorEmail"
						name="NominatorEmail"
						onChange={formik.handleChange}
						type="email"
						value={formik.values.NominatorEmail}
					/>
				</Form.Item>
			</div>

			<div class="form-section">
				<h3 class="pageTitle">AWARD CANDIDATE INFORMATION</h3>
				<p>Please give us information about the person or organization being nominated for awards.</p>
				<Form.Item
					label="Name"
					hasFeedback
					help={formik.errors.CandidateName}
					required={true}
					validateStatus={size(formik.errors) ? (formik.errors.CandidateName ? 'error' : 'success') : null}>
					<Input
						disabled={formik.isSubmitting}
						id="CandidateName"
						name="CandidateName"
						onChange={formik.handleChange}
						type="text"
						value={formik.values.CandidateName}
					/>
				</Form.Item>
				<Form.Item
					label="Title"
					hasFeedback
					help={formik.errors.CandidateTitle}
					required={true}
					validateStatus={size(formik.errors) ? (formik.errors.CandidateTitle ? 'error' : 'success') : null}>
					<Input
						disabled={formik.isSubmitting}
						id="CandidateTitle"
						name="CandidateTitle"
						onChange={formik.handleChange}
						type="text"
						value={formik.values.CandidateTitle}
					/>
				</Form.Item>
				<Form.Item
					label="Organization"
					hasFeedback
					help={formik.errors.CandidateOrganization}
					required={true}
					validateStatus={
						size(formik.errors) ? (formik.errors.CandidateOrganization ? 'error' : 'success') : null
					}>
					<Input
						disabled={formik.isSubmitting}
						id="CandidateOrganization"
						name="CandidateOrganization"
						onChange={formik.handleChange}
						type="text"
						value={formik.values.CandidateOrganization}
					/>
				</Form.Item>
				<Form.Item
					label="Telephone"
					hasFeedback
					help={formik.errors.CandidateTelephone}
					required={true}
					validateStatus={
						size(formik.errors) ? (formik.errors.CandidateTelephone ? 'error' : 'success') : null
					}>
					<Input
						disabled={formik.isSubmitting}
						id="CandidateTelephone"
						name="CandidateTelephone"
						onChange={formik.handleChange}
						type="text"
						value={formik.values.CandidateTelephone}
					/>
				</Form.Item>
				<Form.Item
					label="Email"
					hasFeedback
					help={formik.errors.CandidateEmail}
					required={true}
					validateStatus={size(formik.errors) ? (formik.errors.CandidateEmail ? 'error' : 'success') : null}>
					<Input
						disabled={formik.isSubmitting}
						id="CandidateEmail"
						name="CandidateEmail"
						onChange={formik.handleChange}
						type="email"
						value={formik.values.CandidateEmail}
					/>
				</Form.Item>
			</div>

			<div className="form-section">
				<h3 className="pageTitle">CATEGORIES</h3>
				<p>
					<span class="red">*</span> Please choose the award you are nominating for.
				</p>
				{categoriesLoading && (
					<div class="loading">
						<Spin size="large" />
					</div>
				)}

				{!categoriesLoading && (
					<Form.Item
						hasFeedback
						help={formik.errors.Category}
						required={true}
						validateStatus={size(formik.errors) ? (formik.errors.Category ? 'error' : 'success') : null}>
						<Radio.Group
							id="Category"
							name="Category"
							onChange={e => {
								formik.handleChange(e);
								setSelectedCategory(categories.find(c => c.Id === e.target.value));
								handleCategoryChange(e.target.value);
							}}
							value={formik.values.Category}>
							{categories.map(category => (
								<Radio
									key={category.Id}
									style={{ display: 'block', height: '30px', lineHeight: '30px' }}
									value={category.Id}>
									<span style={{ fontWeight: 'normal' }}>{category.Name}</span> {category.Award}
									<Button type="link" href={`/criteria/${category.Id}`} target="_blank">
										(Criteria)
									</Button>
								</Radio>
							))}
						</Radio.Group>
					</Form.Item>
				)}
			</div>

			{formik.values.Category && (
				<div class="form-section">
					<h3 className="pageTitle">
						<i>{selectedCategory.Name}</i> <strong>{selectedCategory.Award}</strong>
					</h3>
					<p>{selectedCategory.Description}</p>

					<h3 class="pageTitle">CRITERIA</h3>
					<p>Please fill up the criteria of the chosen award you are nomination for.</p>

					{criteriaLoading && (
						<div class="loading">
							<Spin size="large" />
						</div>
					)}

					{!criteriaLoading &&
						criteria.map((d, i) => (
							<Form.Item
								key={d.Id}
								label={d.Name}
								hasFeedback
								help={formik.errors[`Criteria${i + 1}`]}
								required={true}
								validateStatus={
									size(formik.errors)
										? formik.errors[[`Criteria${i + 1}`]]
											? 'error'
											: 'success'
										: null
								}>
								<p>{d.Description}</p>
								<Input.TextArea
									autoSize={{ minRows: 4 }}
									disabled={formik.isSubmitting}
									id={`Criteria${i + 1}`}
									maxLength="5000"
									name={`Criteria${i + 1}`}
									onChange={formik.handleChange}
									style={{ width: '100%' }}
									type="text"
									value={formik.values[`Criteria${i + 1}`]}
								/>
							</Form.Item>
						))}
				</div>
			)}

			<div class="form-section">
				<Form.Item
					label="Additional Information"
					hasFeedback
					help={formik.errors.AdditionalInfo}
					validateStatus={size(formik.errors) ? (formik.errors.AdditionalInfo ? 'error' : 'success') : null}>
					<p>
						Please let us know why the person or organization deserves the award. Try to say it in less than
						500 words but provide as much supporting materials as you wish.
					</p>
					<Input.TextArea
						autoSize={{ minRows: 4 }}
						disabled={formik.isSubmitting}
						id="AdditionalInfo"
						maxLength="5000"
						name="AdditionalInfo"
						onChange={formik.handleChange}
						style={{ width: '100%' }}
						type="text"
						value={formik.values.AdditionalInfo}
					/>
				</Form.Item>
				<Form.Item
					label={`Supporting Documents (max: ${documentFileSizeLimit / 1000}mb)`}
					hasFeedback
					help={formik.errors.SupportingDocuments}
					validateStatus={
						size(formik.errors) ? (formik.errors.SupportingDocuments ? 'error' : 'success') : null
					}>
					<Upload.Dragger
						beforeUpload={() => false}
						disabled={formik.isSubmitting}
						id="SupportingDocuments"
						fileList={formik.values.SupportingDocuments ? formik.values.SupportingDocuments : []}
						multiple={true}
						name="SupportingDocuments"
						onChange={e => {
							formik.setFieldValue(
								'SupportingDocuments',
								e.fileList.map(d => (d.originFileObj ? d.originFileObj : d))
							);

							let total = 0;
							e.fileList.map(d => (total += d.originFileObj ? d.originFileObj.size : d.size));
							formik.setFieldValue('SupportingDocumentsSize', total);
						}}>
						<p className="ant-upload-text">Click or drag file to this area to upload</p>
						<p className="ant-upload-hint">You can select up to 10 supporting documents</p>
					</Upload.Dragger>
					{formik.values.SupportingDocumentsSize && (
						<div style={{ textAlign: 'right' }}>
							<span>
								Total size:{' '}
								<span id="total-file-size">{prettyBytes(formik.values.SupportingDocumentsSize)}</span>
							</span>
						</div>
					)}
				</Form.Item>

				<Button
					className="main-button"
					disabled={formik.isValidating || formik.isSubmitting || !!size(formik.errors)}
					htmlType="submit"
					loading={formik.isSubmitting}
					size="large"
					type="primary">
					Submit
				</Button>
			</div>
		</Form>
	);
};

render(<Nomination />, document.getElementById('PageNominations'));
