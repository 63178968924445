import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import { database } from '@volenday/sdk';

const Judging = () => {
	const [currentJudges, setCurrentJudges] = useState([]);
	const [emeritusJudges, setEmeritusJudges] = useState([]);
	const [boardOfJudgesContent, setBoardOfJudgesContent] = useState('');

	const compareOrder = (a, b) => {
		if (a.Order < b.Order) return -1;
		if (a.Order > b.Order) return 1;
		return 0;
	};

	useEffect(() => {
		(async () => {
			let [{ data: judges }, { data: partials }] = await Promise.all([
				database.get({ entity: 'Judging' }).exec(),
				database
					.get({ entity: 'WebsitePartials' })
					.filter({ Part: 'Our Board of Judges' })
					.exec()
			]);

			const currentJudges = judges.filter(d => d.CurrentJudge).sort(compareOrder);
			setCurrentJudges(currentJudges);
			const emeritusJudges = judges.filter(d => !d.CurrentJudge).sort(compareOrder);
			setEmeritusJudges(emeritusJudges);
			setBoardOfJudgesContent(partials.length !== 0 ? partials[0].Content : '');
		})();
	}, []);

	const renderJudges = ({ BoardOfJudges, Country, Description, Id, Image, Name }) => {
		const imageSize = BoardOfJudges ? { width: 200, height: 240 } : { width: 140, height: 160 };

		return (
			<tr key={Id}>
				<td valign="top">
					<img
						src={Image.url}
						style={{
							border: '1px solid #ccc',
							padding: 1,
							marginRight: 5,
							marginTop: 0,
							marginBottom: 0,
							...imageSize
						}}
					/>
				</td>
				<td valign="top">
					<div class="content-font">
						<b>{Name}</b>
						<br />
						<i>{Country}</i>
						<br />
						<div dangerouslySetInnerHTML={{ __html: Description }} />
					</div>
				</td>
			</tr>
		);
	};

	return (
		<>
			<h3 class="blue">OUR BOARD OF JUDGES</h3>
			{boardOfJudgesContent != '' && <div dangerouslySetInnerHTML={{ __html: boardOfJudgesContent }} />}
			<br />
			<br />
			<table class="judging">
				<tbody>{currentJudges.map(d => renderJudges({ ...d, BoardOfJudges: true }))}</tbody>
			</table>

			<h3 class="blue">ESTEEMED EMERITUS JUDGES</h3>
			<table class="judging">
				<tbody>{emeritusJudges.map(d => renderJudges({ ...d, BoardOfJudges: false }))}</tbody>
			</table>
		</>
	);
};

render(<Judging />, document.getElementById('PageJudging'));
