import jparam from 'jquery-param';
import { formMeta, formToggle } from '../functions';

var Registrations = function() {
	// function FormValues() {
	// 	if (!_current_registration) {
	// 		return;
	// 	}

	// 	for (var key in _current_registration) {
	// 		if (key == 'Events') {
	// 			continue;
	// 		}
	// 		$('[name="' + key + '"]').val(_current_registration[key]);
	// 	}

	// 	var attendees = _current_registration.Attendees;
	// 	for (var i = 0; i < attendees.length; i++) {
	// 		for (var key in attendees[i]) {
	// 			$('[name="' + key + (i + 1) + '"]').val(attendees[i][key]);
	// 		}
	// 	}
	// }

	function AddAttendee(data) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: _attendee_url,
				data: data,
				type: 'POST',
				headers: {
					Authorization: localStorage.getItem('token')
				}
			})
				.done(resolve)
				.fail(reject);
		});
	}

	function copyText(id, count, obj) {
		if (count == 1) {
			$('#' + id).val($(obj).val());
		}
	}

	function GenerateAttendees(n) {
		var output = [];
		for (var i = 0; i < n; i++) {
			var row = `
                <div class="row attendee-row">
                <div class="col-md-12"><p><strong>Attendee ${i + 1}</strong></p></div>
                <div class="col-md-2"><label for="">First Name</label><input type="text" class="form-control" name="FirstName${i +
					1}" data-name="FirstName${i + 1}" required></div>
                <div class="col-md-2"><label for="">Last Name</label><input type="text" class="form-control" name="LastName${i +
					1}" data-name="LastName${i + 1}" required></div>
                <div class="col-md-2"><label for="">Company</label><input type="text" class="form-control" name="Company${i +
					1}" data-name="Company${i + 1}" required></div>
                <div class="col-md-2"><label for="">Title</label><input type="text" class="form-control" name="Title${i +
					1}" data-name="Title${i + 1}"></div>
                <div class="col-md-2"><label for="">Email</label><input type="email" class="form-control" name="Email${i +
					1}" data-name="Email${i + 1}" required></div>
                <div class="col-md-2"><label for="">Telephone</label><input  type="text" class="form-control" name="Telephone${i +
					1}" data-name="Telephone${i + 1}"></div>
                </div>`;

			output.push(row);
		}

		$('.attendee-container').html(output.join(''));

		// hide payer if 1 attendee
		$('.payee-container *').val('');
		$('.payee-container').removeClass('hidden');
		if (n == 1) {
			$('.payee-container').addClass('hidden');
			$('input[data-name=FirstName1]').on('blur', function(e) {
				copyText('PayerFirstName', n, 'input[data-name=FirstName1]');
			});
			$('input[data-name=LastName1]').on('blur', function(e) {
				copyText('PayerLastName', n, 'input[data-name=LastName1]');
			});
			$('input[data-name=Email1]').on('blur', function(e) {
				copyText('PayerEmail', n, 'input[data-name=Email1]');
			});
			$('input[data-name=Telephone1]').on('blur', function(e) {
				copyText('PayerContact', n, 'input[data-name=Telephone1]');
			});
		}
	}

	function getAuthenticationToken() {
		$.ajax({
			url: '/home/getAuthenticationToken',
			type: 'POST',
			dataType: 'json',
			success: function(data) {
				localStorage.setItem('token', data.token);
			}
		});
	}

	function guid() {
		// function s4() {
		//     // return Math.floor((1 + Math.random()) * 0x10000)
		//     //     .toString(16)
		//     //     .substring(1);
		// }
		// return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
		//     s4() + '-' + s4() + s4() + s4();
		return new Date().getTime().toString(12) + new Date().getTime().toString(36);
	}

	return {
		initForm: function() {
			$('.form-registration').on('submit', function(e) {
				e.preventDefault();
				var form = formMeta($(this));
				let registrationFee = parseInt(
					$(this)
						.find('#registration-fee')
						.val()
				);
				var self = $(this);

				var attendees = [];
				for (var i = 0; i < parseInt($('.TicketsCount').val()); i++) {
					var data = {
						FirstName: $(`[data-name="FirstName${i + 1}"]`).val(),
						LastName: $(`[data-name="LastName${i + 1}"]`).val(),
						Company: $(`[data-name="Company${i + 1}"]`).val(),
						Email: $(`[data-name="Email${i + 1}"]`).val(),
						Title: $(`[data-name="Title${i + 1}"]`).val(),
						Telephone: $(`[data-name="Telephone${i + 1}"]`).val(),
						TableNo: 0,
						SeatNo: 0
					};

					attendees.push(AddAttendee(data));
				}

				form.submitButton.attr('disabled', true);
				form.submitButton.html('Sending');

				Promise.all(attendees)
					.then(function(response) {
						const attendeeIds = response.map(d => d.content.Id);

						self.ajaxSubmit({
							headers: {
								Authorization: localStorage.getItem('token')
							},
							url: form.action,
							type: form.method,
							data: {
								OrderId: guid(),
								Attendees: attendeeIds,
								TotalAmount: attendeeIds.length * registrationFee
							},
							beforeSubmit: function() {
								formToggle(self, 'disable');
								form.submitButton.html('Sending');
							},
							success: ({ content }) => {
								formToggle(self, 'enable');
								form.submitButton.html('Submit');
								$('.form-registration')[0].reset();
								toastr.success('Transferring you to payment', 'Success!');

								const { Events, OrderId } = content;
								if (Events.RegistrationFee) {
									location.href = `/registrations/confirm?${jparam({
										orderId: OrderId,
										// eventType: this.props.id,
										isReturnedFrom: false,
										previousClientKey: null,
										previousPaymentIntentId: null
									})}`;
								} else {
									location.href = `/registrations/message?${jparam({
										orderId: OrderId,
										type: 'Successful'
									})}`;
								}

								//scroll to top
								$('html, body').animate(
									{
										scrollTop: '0px'
									},
									500
								);
							},
							error: function(data) {
								toastr.error('Something went wrong. Please try again.', 'Error!');
								formToggle(self, 'enable');
								form.submitButton.html('Submit');
							}
						});
					})
					.catch(err => console.log(err));
			});
		},
		initAttendees: function() {
			$('.TicketsCount').change(function(e) {
				var n = parseInt(e.target.value) || 1;
				GenerateAttendees(n);
			});
		},
		init: function() {
			this.initForm();
			this.initAttendees();
			getAuthenticationToken();

			//initialize depending on ticket count
			GenerateAttendees($('.TicketsCount').val());

			// FormValues();
		}
	};
};

$(document).ready(function() {
	Registrations().init();
});
