/**
 * FACEBOOK SHARE
 * @param {*} url 
 * @param {*} title 
 */
window.fbAsyncInit = function () {
    FB.init({
        appId: facebookAppId,
        xfbml: true,
        version: 'v2.8'
    });
    FB.AppEvents.logPageView();
};

(function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

$('.facebookShare').on('click', function (e) {
    const button = $(this),
        url = button.data('url');
        
    FB.ui({
        method: 'share',
        display: 'popup',
        href: url,
    }, function (response) { });

    e.preventDefault();
});