const entities = {
	Sponsor2: 'SponsorsVersion2',
	SponsorCategory: 'SponsorCategoryVersion2',
	Media: 'Media',
	Events: 'Events',
	Revolver: 'PastSpeakers',
	Attendee: 'Attendees',
	ImageSlider: 'ImageSlider',
	Judging: 'Judging',
	Subscriptions: 'Subscriptions',
	Nominations: 'Nominations',
	NominationRecipients: 'NominationRecepients',
	NominationCategories: 'NominationCategory',
	Registrations: 'Registrations',
	Library: 'Library',
	NewHistory: 'History',
	NewPhotos: 'NewPhotos',
	Users: '_users',
	UserTypes: '_userTypes',
	Configurations: '_configurations',
	About: 'About'
};

module.exports = entities;
