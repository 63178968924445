import { render } from 'react-dom';
import React, { Fragment, useState, useEffect } from 'react';
import { database } from '@volenday/sdk';

import { Sponsor2, SponsorCategory } from '../../entities';

const Sponsor = () => {
	const [categories, setCategories] = useState([]);
	const [sponsors, setSponsors] = useState([]);

	useEffect(() => {
		(async () => {
			let [{ data: sponsors }, { data: categories }] = await Promise.all([
				database
					.get({ entity: Sponsor2 })
					.all()
					.exec(),
				database
					.get({ entity: SponsorCategory })
					.all()
					.sort({ Order: 1 })
					.exec()
			]);

			setCategories(categories);
			setSponsors(sponsors);
		})();
	}, []);

	return (
		<>
			<br />
			<br />
			{categories
				.sort((a, b) => (a.Order > b.Order ? 1 : -1))
				.map(d => {
					let imageWidth = 250;
					let imageHeight = 167;
					let dividerWidth = 0;
					let dividerHeight = 0;

					switch (d.Name) {
						case 'GRAND TITLE SPONSOR':
							// FOR ROBINSON LOGO, BELOW IS THE DEFAULT
							imageWidth = 315;
							imageHeight = 210;
							// imageWidth = 350;
							// imageHeight = 170;
							break;
						case 'ELITE AWARD SPONSORS':
							imageWidth = 250;
							imageHeight = 167;
							dividerWidth = 2;
							dividerHeight = 150;
							break;
						case 'OFFICIAL VENUE':
							imageWidth = 250;
							imageHeight = 111;
						case 'TECHNOLOGY PARTNERS':
							imageWidth = 250;
							imageHeight = 111;
						case 'OFFICIAL KNOWLEDGE PARTNER':
							imageWidth = 250;
							imageHeight = 111;
						case 'PRESTIGE SPONSORS':
							imageWidth = 250;
							imageHeight = 111;
							dividerWidth = 2;
							dividerHeight = 105;
							break;
						case 'ACCLAIMED MEDIA':
							imageWidth = 150;
							imageHeight = 75;
							dividerWidth = 2;
							dividerHeight = 50;
							break;
						case 'SUPPORTER CHAMPIONS':
							imageWidth = 150;
							imageHeight = 75;
							dividerWidth = 2;
							dividerHeight = 45;
							break;
					}

					let filteredSponsors = sponsors
						.filter(s => s.Category.Name == d.Name)
						.sort((a, b) => {
							if (a.Name < b.Name) return -1;
							if (a.Name > b.Name) return 1;
							return 0;
						})
						.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1));

					let chunk = 2;
					let chunkedSponsors = [];

					for (let i = 0; i < filteredSponsors.length; i += chunk) {
						chunkedSponsors.push(filteredSponsors.slice(i, i + chunk));
					}

					return (
						<div className="clearfix boxContainer" key={d.Id}>
							<h3 className="pageTitle">{d.Name}</h3>
							<div className="col-lg-12 col-md-12 col-sm-12 nopadding">
								{chunkedSponsors.map((sponsor, index) => {
									return (
										<Fragment key={index}>
											<div style={{ marginBottom: '10px' }}>
												{sponsor.length === 2 ? (
													<>
														<a
															href={sponsor[0].Url}
															target="_blank"
															style={{ float: 'left' }}>
															<img
																src={sponsor[0].Image.url}
																align="center"
																width={imageWidth}
																height={imageHeight}
															/>
														</a>
														<div
															style={{
																marginLeft: '12px',
																marginRight: '12px',
																borderRight: '2px solid orange',
																width: `${dividerWidth}px`,
																height: `${dividerHeight}px`,
																float: 'left'
															}}
														/>
														<a href={sponsor[1].Url} target="_blank">
															<img
																src={sponsor[1].Image.url}
																align="center"
																width={imageWidth}
																height={imageHeight}
															/>
														</a>
													</>
												) : (
													<a href={sponsor[0].Url} target="_blank" style={{ float: 'left' }}>
														<img
															src={sponsor[0].Image.url}
															align="center"
															width={imageWidth}
															height={imageHeight}
														/>
													</a>
												)}
											</div>
											<br />
										</Fragment>
									);
								})}
							</div>
						</div>
					);
				})}
		</>
	);
};

render(<Sponsor />, document.getElementById('PageSponsors'));
