const nodemailer = require('nodemailer');
const os = require('os');

const environment = 'production'
exports.apiEmailAddress = 'no-reply@volenday.com';
exports.apiPassword = 'V0l3nd@YP@ssw0rd';
exports.apiKey = '1a0d3ef0-85e7-11ef-b209-61f6cdb9aa07';
exports.applicationId = '19a70310-85e7-11ef-b209-61f6cdb9aa07';
exports.REACT_APP_RECAPTCHA_SITE_KEY = '6LdPW8gqAAAAAB_6j_pOyO-d79hoFwRFE7MDb5gi';
exports.authCredentials = {
	apiKey: '1a0d3ef0-85e7-11ef-b209-61f6cdb9aa07',
	emailAddress: 'no-reply@volenday.com',
	environment,
	password: 'V0l3nd@YP@ssw0rd'
};
exports.isProduction = environment === 'production';
exports.recaptchaSiteKey = '6Lf2zg0TAAAAAEcmJqHYJkCni7qwWZax_8QDdaE3';

exports.apiUrl = {
	development: 'https://dev.api.ahamatic.com/',
	sandbox: 'https://test.api.ahamatic.com/',
	staging: 'https://test.api.ahamatic.com/',
	production: 'https://api.ahamatic.com/'
}[environment];

exports.facebookAppId = {
	development: '1302274103141628',
	sandbox: '1302274103141628',
	staging: '1302274103141628',
	production: '1302271043141934'
}[environment];

exports.siteUrl = {
	development: 'http://localhost:8003/',
	sandbox: 'http://test.asia-ceo-awards.volenday.com/',
	staging: 'http://test.asia-ceo-awards.volenday.com/',
	production: 'https://www.asia-ceo-awards.org/'
}[environment];

var mailerEmail = (exports.mailerEmail = 'no-reply@volenday.com');
var mailerPassword = (exports.mailerPassword = 'dzbqimpfnolaakaf');

exports.transporter = nodemailer.createTransport({
	service: 'Gmail',
	auth: {
		user: mailerEmail,
		pass: mailerPassword
	}
});

exports.getIP = () => {
	var interfaces = os.networkInterfaces();
	var addresses = [];
	for (var k in interfaces) {
		for (var k2 in interfaces[k]) {
			var address = interfaces[k][k2];
			if (address.family === 'IPv4' && !address.internal) {
				addresses.push(address.address);
			}
		}
	}

	return addresses[0];
};

exports.statuses = {
	0: 'Successful',
	1: 'Failed',
	2: 'Pending',
	10: 'Refunded',
	15: 'Authorized',
	16: 'Captured',
	31: 'Reversal Pending',
	9: 'Reversed',
	'-1': 'Not Found',
	'-2': 'System Error'
};

exports.paymongoPublicKey = {
	development: 'pk_test_xXnrMaBMFEbypdqeooX3Gbgd',
	sandbox: 'pk_test_xXnrMaBMFEbypdqeooX3Gbgd',
	staging: 'pk_test_xXnrMaBMFEbypdqeooX3Gbgd',
	production: 'pk_live_2oeM2kES377TQ2T7QkeykzC8'
}[environment];