import React from 'react';
import { render } from 'react-dom';
import { Button, Form, Input, message } from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { size } from 'lodash';
import ReCAPTCHA from '../component/Recaptcha';
import { auth, database } from '@volenday/sdk';
import axios from 'axios';

import { apiUrl, authCredentials, REACT_APP_RECAPTCHA_SITE_KEY } from '../../variables';

import { About } from '../../entities';

const Index = () => {
	ReCAPTCHA();

	const validationSchema = yup.object().shape({
		Name: yup.string().required('Name is a required field'),
		Mobile: yup.string().required('Moblile is a required field'),
		Email: yup
			.string()
			.email('Email is not a valid email')
			.required('Email is a required field'),
		Message: yup.string().required('Message is a required field')
	});

	const formik = useFormik({
		initialValues: { Name: '', Mobile: '', Email: '', Message: '' },
		validationSchema,
		onSubmit: async values => {
			try {
				grecaptcha.enterprise.ready(async () => {
					const gRecaptchaToken = await grecaptcha.enterprise.execute(REACT_APP_RECAPTCHA_SITE_KEY, {
						action: 'INQUIRY_SUBMIT'
					});

					const { token } = await auth.loginWithEmail({
						...authCredentials
					});

					const response = await axios({
						method: 'post',
						url: `${apiUrl}api/recaptcha/verify`,
						data: {
							googleRecaptchaToken: gRecaptchaToken,
							expectedAction: 'INQUIRY_SUBMIT',
							siteKey: REACT_APP_RECAPTCHA_SITE_KEY
						},
						headers: {
							Authorization: `Bearer ${token}`,
							'Content-Type': 'application/json'
						}
					});

					if (response.status == 200) {
						try {
							await database
								.create({ entity: About })
								.data(values)
								.exec();
							formik.resetForm();
							return message.success('Sucessfully Sent');
						} catch (error) {
							return message.error(error.message);
						}
					}
				});
			} catch (error) {
				return message.error(error.message);
			}
		}
	});

	const disabled = formik.isSubmitting;

	return (
		<>
			<h1 className="overview-h1">Contact Us Now!</h1>
			<hr className="overview-hr" />

			<Form onSubmit={formik.handleSubmit}>
				<Form.Item
					colon={false}
					label="Name"
					hasFeedback
					help={formik.errors.Name}
					validateStatus={size(formik.errors) ? (formik.errors.Name ? 'error' : 'success') : null}>
					<Input
						disabled={disabled}
						id="Name"
						name="Name"
						onChange={formik.handleChange}
						size="large"
						type="text"
						value={formik.values.Name}
					/>
				</Form.Item>

				<Form.Item
					colon={false}
					label="Mobile"
					hasFeedback
					help={formik.errors.Mobile}
					validateStatus={size(formik.errors) ? (formik.errors.Mobile ? 'error' : 'success') : null}>
					<Input
						disabled={disabled}
						id="Mobile"
						name="Mobile"
						onChange={formik.handleChange}
						size="large"
						type="text"
						value={formik.values.Mobile}
					/>
				</Form.Item>

				<Form.Item
					colon={false}
					label="Email"
					hasFeedback
					help={formik.errors.Email}
					validateStatus={size(formik.errors) ? (formik.errors.Email ? 'error' : 'success') : null}>
					<Input
						disabled={disabled}
						id="Email"
						name="Email"
						onChange={formik.handleChange}
						size="large"
						type="email"
						value={formik.values.Email}
					/>
				</Form.Item>

				<Form.Item
					colon={false}
					label="Message"
					hasFeedback
					help={formik.errors.Message}
					validateStatus={size(formik.errors) ? (formik.errors.Message ? 'error' : 'success') : null}>
					<Input.TextArea
						autoSize={{ minRows: 4 }}
						disabled={disabled}
						id="Message"
						name="Message"
						onChange={formik.handleChange}
						size="large"
						type="text"
						value={formik.values.Message}
					/>
				</Form.Item>

				<br />

				<Button
					className="main-button"
					disabled={formik.isValidating || formik.isSubmitting || !!size(formik.errors)}
					htmlType="submit"
					loading={formik.isValidating || formik.isSubmitting}
					size="large"
					type="primary">
					{formik.isValidating || formik.isSubmitting ? 'Sending...' : 'Send'}
				</Button>
			</Form>
		</>
	);
};

render(<Index />, document.getElementById('about'));
