import React, { useCallback, useEffect, useState } from 'react';
import { createRoot } from 'react-dom';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import { database } from '@volenday/sdk';
import { has } from 'lodash';
import Cards from 'react-credit-cards';
import params from 'jquery-param';

import { Box, Button, CircularProgress } from '@mui/material/';

import images from 'react-payment-inputs/images';

const PaymentForm = () => {
	isReturnedFrom = JSON.parse(isReturnedFrom);

	const showNotification = () => {};
	const [cvc, setCvc] = useState('');
	const [expiryDate, setExpiryDate] = useState('');
	const [focus, setFocus] = useState('');
	const [name, setName] = useState('');
	const [cardNumber, setCardNumber] = useState('');
	const [issuer, setIssuer] = useState('');
	const [loading, setLoading] = useState(false);
	const [isValidating, setIsValidating] = useState(false);
	const [isRedirecting, setIsRedirecting] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const [snackbarState, setSnackbarState] = useState({
		open: false,
		message: ''
	});

	const {
		getCardImageProps,
		getCardNumberProps,
		getExpiryDateProps,
		getCVCProps,
		meta,
		wrapperProps
	} = usePaymentInputs();

	const handleCvcChange = useCallback(e => setCvc(e.target.value), []);
	const handleCardNumberChange = useCallback(e => setCardNumber(e.target.value), []);
	const handleCardNameChange = useCallback(e => setName(e.target.value), []);

	const handleExpiryDateChange = useCallback(e => {
		const splittedDate = e.target.value.split(' ');
		const joinedDate = splittedDate.join('');
		setExpiryDate(joinedDate);
	}, []);

	const handleInputFocus = useCallback(e => setFocus(e.target.name), []);

	const handleSubmit = useCallback(
		async e => {
			e.preventDefault();

			setLoading(true);

			const splittedExpiryDate = expiryDate.split('/');
			const month = splittedExpiryDate[0];
			const year = splittedExpiryDate[1];

			const trimmedCardNumber = cardNumber.split(' ').join('');

			const paymentMethodRequest = await fetch('https://api.paymongo.com/v1/payment_methods', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					Authorization: `Basic ${paymongoPublicKey}`,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					data: {
						attributes: {
							type: 'card',
							details: {
								card_number: trimmedCardNumber,
								exp_month: parseInt(month),
								exp_year: parseInt(year),
								cvc
							},
							billing: {
								name: `${payerFirstName} ${payerLastName}`,
								email: payerEmail,
								phone: payerContact
							}
						}
					}
				})
			});

			if (!paymentMethodRequest.ok) {
				const errorResponse = JSON.parse(await paymentMethodRequest.text());
				const errorMessage = errorResponse.errors[0]
					? errorResponse.errors[0].detail.replace('_', ' ').replace('details.', '')
					: 'Something went wrong';
				setLoading(false);

				return showNotification({
					title: 'Error',
					message: errorMessage,
					color: 'red'
				});
			}

			const paymentMethodResponse = await paymentMethodRequest.json();

			const paymentIntentAttachmentRequest = await fetch(
				`https://api.paymongo.com/v1/payment_intents/${paymongoPaymentIntentId}/attach`,
				{
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Basic ${paymongoPublicKey}`
					},
					body: JSON.stringify({
						data: {
							attributes: {
								payment_method: paymentMethodResponse.data.id,
								client_key: paymongoClientKey,
								return_url: `${siteUrl}registrations/confirm?${params({
									orderId,
									eventType,
									isReturnedFrom: true,
									previousClientKey: paymongoClientKey,
									previousPaymentIntentId: paymongoPaymentIntentId
								})}`
							}
						}
					})
				}
			);

			if (!paymentIntentAttachmentRequest) console.log(JSON.parse(await paymentIntentAttachmentRequest.text()));

			const paymentIntentAttachmentResponse = await paymentIntentAttachmentRequest.json();

			if (has(paymentIntentAttachmentResponse, 'errors')) {
				setLoading(false);

				return paymentIntentAttachmentResponse.errors.map(d => {
					switch (d.sub_code) {
						case 'card_expired':
							showNotification({
								color: 'red',
								message: `${d.detail} Please use another form of payment.`,
								title: 'Failed'
							});
							break;
						case 'cvc_invalid':
							showNotification({
								color: 'red',
								message: `${d.detail} Please correct the CVC/CVN before re-submitting again.`,
								title: 'Failed'
							});
							break;
						case 'generic_decline':
						case 'processor_unavailable':
							showNotification({ color: 'red', message: `${d.detail}`, title: 'Failed' });
							break;
						case 'fraudulent':
						case 'processor_blocked':
						case 'lost_card':
						case 'stolen_card':
						case 'blocked':
							// Paymongo suggests not to expose the error detail
							showNotification({
								color: 'red',
								message:
									'The card has been declined by the issuing bank. Please contact them for more information.',
								title: 'Failed'
							});
							break;
						case 'insufficient_funds':
							showNotification({
								color: 'red',
								message: `${d.detail} Please use another form of payment.`,
								title: 'Failed'
							});
							break;
					}
				});
			}

			if (
				!paymentIntentAttachmentResponse.data.attributes.next_action &&
				paymentIntentAttachmentResponse.data.attributes.status === 'succeeded'
			) {
				setIsRedirecting(true);

				setTimeout(() => {
					location.href = `/registrations/message?${params({
						orderId: orderId,
						type: 'Successful'
					})}`;
				}, [1500]);
			}

			if (
				paymentIntentAttachmentResponse.data.attributes.next_action &&
				paymentIntentAttachmentResponse.data.attributes.next_action.type === 'redirect'
			)
				location.href = paymentIntentAttachmentResponse.data.attributes.next_action.redirect.url;
		},
		[cvc, expiryDate, name, cardNumber, issuer]
	);

	const handleCallback = useCallback(e => setIssuer(e.issuer), []);

	useEffect(() => {
		(async () => {
			if (isReturnedFrom && previousClientKey !== '' && previousPaymentIntentId !== '') {
				setIsValidating(true);

				const previousPaymentIntentRequest = await fetch(
					`https://api.paymongo.com/v1/payment_intents/${previousPaymentIntentId}?client_key=${previousClientKey}`,
					{
						method: 'GET',
						headers: {
							Accept: 'application/json',
							Authorization: `Basic ${paymongoPublicKey}`
						}
					}
				);

				const { data } = await previousPaymentIntentRequest.json();

				switch (data.attributes.status) {
					case 'awaiting_payment_method':
					case 'awaiting_capture':
						setIsValidating(false);

						if (data.attributes.last_payment_error)
							return showNotification({
								color: 'red',
								message: data.attributes.last_payment_error,
								title: 'Failed'
							});

						showNotification({
							color: 'red',
							message: 'Something went wrong, please try again.',
							title: 'Failed'
						});

						await database
							.update({ entity, environment, id: registrationId, token })
							.data({ TransactionId: paymongoPaymentIntentId })
							.exec();
						break;
					case 'succeeded':
						setIsValidating(false);
						setIsRedirecting(true);

						setTimeout(() => {
							location.href = `/registrations/message?${params({
								orderId,
								type: 'Successful',
								transactionId: previousPaymentIntentId
							})}`;
						}, [1500]);
						break;
					case 'processing':
						setIsValidating(false);
						setIsProcessing(true);

						setTimeout(async () => {
							setIsProcessing(false);
							setIsValidating(true);

							const paymentIntentRequest = await fetch(
								`https://api.paymongo.com/v1/payment_intents/${previousPaymentIntentId}?client_key=${previousClientKey}`,
								{
									method: 'GET',
									headers: {
										Accept: 'application/json',
										Authorization: `Basic ${paymongoPublicKey}`
									}
								}
							);

							const { data } = await paymentIntentRequest.json();

							switch (data.attributes.status) {
								case 'awaiting_payment_method':
								case 'awaiting_capture':
									setIsValidating(false);

									if (data.attributes.last_payment_error)
										return showNotification({
											color: 'red',
											message: data.attributes.last_payment_error,
											title: 'Failed'
										});

									showNotification({
										color: 'red',
										message: 'Something went wrong, please try again.',
										title: 'Failed'
									});

									await database
										.update({
											entity,
											environment,
											id: registrationId,
											token
										})
										.data({
											TransactionId: paymongoPaymentIntentId
										})
										.exec();
									break;
								case 'succeeded':
									setIsValidating(false);
									setIsRedirecting(true);

									setTimeout(() => {
										location.href = `/registrations/message?${params({
											orderId,
											type: 'Successful',
											transactionId: previousPaymentIntentId
										})}`;
									}, [1000]);
									break;
							}
						}, [1000]);
						break;
				}
			}
		})();
	}, []);

	// const action = (
	// 	<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
	// 		<CloseIcon fontSize="small" />
	// 	</IconButton>
	// );
	const renderForm = useCallback(() => {
		if (isValidating) {
			return (
				<Box>
					<CircularProgress />
					<h2>Validating your payment...</h2>
				</Box>
			);
		}

		if (isRedirecting) {
			return (
				<Box>
					<CircularProgress color="success" />
					<h2>Payment complete, you will be redirected in a few seconds...</h2>
				</Box>
			);
		}

		if (isProcessing) {
			return (
				<Box>
					<CircularProgress />
					<h2>Processing your payment...</h2>
				</Box>
			);
		}

		return (
			<div>
				<h4>Payment</h4>
				<Cards
					callback={handleCallback}
					cvc={cvc}
					expiry={expiryDate}
					focused={focus}
					name={name}
					number={cardNumber}
				/>
				<form onSubmit={handleSubmit} style={{ marginTop: '10px' }}>
					<Box
						style={{ margin: 'auto' }}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
						<PaymentInputsWrapper {...wrapperProps}>
							<input
								disabled={loading}
								id="name"
								name="name"
								onChange={handleCardNameChange}
								onFocus={handleInputFocus}
								placeholder="Cardholder Name"
							/>
							<svg {...getCardImageProps({ images })} />
							<input
								disabled={loading}
								{...getCardNumberProps({
									onChange: handleCardNumberChange,
									onFocus: handleInputFocus
								})}
							/>
							<input
								disabled={loading}
								{...getExpiryDateProps({
									onChange: handleExpiryDateChange,
									onFocus: handleInputFocus
								})}
							/>
							<input
								disabled={loading}
								{...getCVCProps({
									onChange: handleCvcChange,
									onFocus: handleInputFocus
								})}
							/>
						</PaymentInputsWrapper>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
						style={{ marginTop: '10px' }}>
						<Button
							variant="contained"
							disabled={(meta.error && meta.error !== '') || issuer === 'unknown' || loading}
							loading={loading}
							type="submit">
							{!loading ? 'Submit Payment' : 'Submitting payment...'}
						</Button>
					</Box>
				</form>
			</div>
		);
	}, [isValidating, isRedirecting, isProcessing, cvc, expiryDate, focus, name, cardNumber]);
	return renderForm();
};

const container = document.getElementById('react-payment-form');
const root = createRoot(container);
root.render(<PaymentForm />);
