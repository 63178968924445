import async from 'async';
import Lightbox from 'react-images';
import Gallery from 'react-photo-gallery';
import Pagination from 'react-js-pagination';
import { render } from 'react-dom';
import React, { Component, Fragment } from 'react';
import { database } from '@volenday/sdk';

import { NewPhotos } from '../../entities';

category = JSON.parse(category);

class HistoryPhotos extends Component {
	constructor(props) {
		super(props);

		this.state = {
			photos: [],
			itemsCount: 0,
			currentPage: 0,
			currentImage: 0,
			isLoading: false,
			totalItemsCount: 0,
			lightboxIsOpen: false
		};

		['handlePageChange', 'openLightbox', 'closeLightbox', 'gotoNext', 'gotoPrevious'].map(
			fn => (this[fn] = this[fn].bind(this))
		);
	}

	getPhotoOrientation(photo) {
		return new Promise((resolve, reject) => {
			let img = new Image();

			img.onload = () => {
				if (img.naturalWidth > img.naturalHeight) {
					resolve({ orientation: 'landscape' });
				} else if (img.naturalWidth < img.naturalHeight) {
					resolve({ orientation: 'portrait' });
				}
			};

			img.src = photo.Image.thumbUrl;
		});
	}

	async componentDidMount() {
		try {
			let response = await database
				.get({ entity: NewPhotos })
				.filter({ NewHistoryCategories: category.Id })
				.size(10)
				.page(1)
				.sort({ Order: 1 })
				.exec();
			let newPhotosData = [];

			async.eachSeries(
				response.data,
				(photo, callback) => {
					this.getPhotoOrientation(photo).then(o => {
						photo.orientation = o.orientation;
						newPhotosData.push(photo);
						callback();
					});
				},
				async err => {
					await this.setState({
						photos: [...newPhotosData],
						itemsCount: response.data.length,
						currentPage: response.currentPage,
						totalItemsCount: response.totalResults
					});
				}
			);
		} catch (error) {
			console.log('error: ', error);
		}
	}

	async handlePageChange(pageNumber) {
		try {
			await this.setState({ isLoading: true });

			let response = await database
				.get({ entity: NewPhotos })
				.filter({ NewHistoryCategories: category.Id })
				.size(10)
				.page(pageNumber)
				.sort({ Order: 1 })
				.exec();
			let newPhotosData = [];

			async.eachSeries(
				response.data,
				(photo, callback) => {
					this.getPhotoOrientation(photo).then(o => {
						photo.orientation = o.orientation;
						newPhotosData.push(photo);
						callback();
					});
				},
				async err => {
					await this.setState({
						isLoading: false,
						photos: [...newPhotosData],
						itemsCount: response.data.length,
						currentPage: response.currentPage
					});
				}
			);
		} catch (error) {
			console.log('error: ', error);
		}
	}

	openLightbox(event, obj) {
		this.setState({
			currentImage: obj.index,
			lightboxIsOpen: true
		});
	}

	closeLightbox() {
		this.setState({
			currentImage: 0,
			lightboxIsOpen: false
		});
	}

	gotoPrevious() {
		this.setState({ currentImage: this.state.currentImage - 1 });
	}

	gotoNext() {
		this.setState({ currentImage: this.state.currentImage + 1 });
	}

	render() {
		let { photos, currentPage, itemsCount, totalItemsCount, currentImage, lightboxIsOpen, isLoading } = this.state;

		const images = photos.map(photo => {
			return {
				src: photo.Image.thumbUrl,
				width: photo.orientation === 'landscape' ? 4.5 : photo.orientation === 'portrait' ? 3 : 0,
				height: photo.orientation === 'landscape' ? 3 : photo.orientation === 'portrait' ? 4 : 0
			};
		});

		const hdImages = photos.map(photo => {
			return {
				src: photo.Image.url,
				width: photo.orientation === 'landscape' ? 4.5 : photo.orientation === 'portrait' ? 3 : 0,
				height: photo.orientation === 'landscape' ? 3 : photo.orientation === 'portrait' ? 4 : 0
			};
		});

		return (
			<Fragment>
				<div>
					<div style={isLoading ? { opacity: 0.3 } : { opacity: 1 }}>
						<Gallery photos={images} onClick={this.openLightbox} />
					</div>
					<div
						style={
							isLoading
								? { opacity: 1, position: 'absolute', top: '540px', left: '360px' }
								: { opacity: 0 }
						}>
						<i class="fa fa-spinner fa-pulse fa-5x fa-spin" aria-hidden="true" />
					</div>
				</div>
				<Lightbox
					images={hdImages}
					isOpen={lightboxIsOpen}
					currentImage={currentImage}
					onClickNext={this.gotoNext}
					onClose={this.closeLightbox}
					onClickPrev={this.gotoPrevious}
				/>
				<Pagination
					pageRangeDisplayed={7}
					activePage={currentPage}
					itemsCountPerPage={itemsCount}
					onChange={this.handlePageChange}
					totalItemsCount={totalItemsCount}
				/>
			</Fragment>
		);
	}
}

render(<HistoryPhotos />, document.getElementById('history-photos'));
