import { useEffect } from 'react';

import { REACT_APP_RECAPTCHA_SITE_KEY } from '../../variables';
export default function RecaptchaProvider() {
	useEffect(() => {
		const initRecaptcha = async () => {
			if (window.grecaptcha) {
				await window.grecaptcha.enterprise.ready();
			}
		};

		if (typeof window !== 'undefined') {
			const script = document.createElement('script');
			script.src = `https://www.google.com/recaptcha/enterprise.js?render=${REACT_APP_RECAPTCHA_SITE_KEY}`;
			script.async = true;
			script.onload = () => {
				initRecaptcha();
			};
			document.body.appendChild(script);
		}
	}, []);

	return null;
}
