import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import entities from '../../entities';
import { database } from '@volenday/sdk';

const Media = () => {
	const [data, setData] = useState([]);
	const [fetching, setFetching] = useState(false);

	useEffect(() => {
		(async () => {
			const { data } = await database.get({ entity: entities.Media }).exec();
			setData(data);
			setFetching(false);
		})();
	}, []);

	const createThumbnailContent = (data, index) => {
		const { Id, VideoTitle, YoutubeUrl } = data;

		let cols = index >= 14 ? 3 : 6,
			divHeight = index >= 14 ? 159 : 290,
			videoClass = index >= 14 ? 'video-title-sm' : 'video-title-lg',
			videoLink = index >= 14 ? 'video-link-sm' : 'video-link-lg';
		let editURL = YoutubeUrl.replace('watch?v=', 'v/');
		let youtubeID = editURL.substring(editURL.indexOf('v/') + 2, editURL.length);

		if (youtubeID.match(/&t/g)) {
			youtubeID = youtubeID.split('&t')[0];
		}

		if (youtubeID.match(/ttps/g)) {
			youtubeID = youtubeID.split('/')[3];
		}

		return (
			<div key={Id} class={`col-lg-${cols} col-md-${cols} col-sm-${cols} video-container clearfix`}>
				<a href={YoutubeUrl} class={`${videoLink} data-lity`}>
					<div class="media-thumbnails">
						<div class={`${videoClass} nopadding clearfix`}>
							<p style={{ textAlign: 'center' }}>{VideoTitle}</p>
						</div>
						<img
							width="100%"
							height={divHeight}
							class="thumbnail"
							src={`https://img.youtube.com/vi/${youtubeID}/hqdefault.jpg`}
						/>
					</div>
				</a>
			</div>
		);
	};

	if (fetching)
		return (
			<div style={{ textAlign: 'center' }}>
				<i style={{ fontSize: '19rem' }} class="fa fa-spinner fa-pulse fa-fw" />
			</div>
		);

	if (!fetching) return <div>{data.map(createThumbnailContent)}</div>;
};

ReactDOM.render(<Media />, document.getElementById('gridMedia'));
