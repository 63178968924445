import React, { useEffect, useState } from 'react';
import { remove } from 'lodash';
import { render } from 'react-dom';
import { database } from '@volenday/sdk';

import { Sponsor2, SponsorCategory } from '../entities';

const Sidebar = () => {
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sponsors, setSponsors] = useState([]);

	useEffect(() => {
		(async () => {
			let [{ data: sponsors }, { data: categories }] = await Promise.all([
				database
					.get({ entity: Sponsor2 })
					.all()
					.exec(),
				database
					.get({ entity: SponsorCategory })
					.all()
					.sort({ Order: 1 })
					.exec()
			]);

			setCategories(categories);
			setLoading(false);
			setSponsors(sponsors);

			$('.slider-ACCLAIMED-MEDIA').unslider({
				nav: false,
				keys: false,
				arrows: false,
				animation: 'horizontal',
				autoplay: true
			});
			$('.slider-ELITE-AWARD-SPONSORS').unslider({
				nav: false,
				keys: false,
				arrows: false,
				animation: 'horizontal',
				autoplay: true
			});
			$('.slider-SUPPORTER-CHAMPIONS').unslider({
				nav: false,
				keys: false,
				arrows: false,
				animation: 'horizontal',
				autoplay: true
			});
		})();
	}, []);

	return (
		<>
			{loading ? (
				<div class="loader">
					<div>
						<i class="fa fa-spinner fa-pulse fa-fw fa-3x" />
					</div>
				</div>
			) : (
				categories.map((d, i) => {
					return (
						<div key={i} class="sponsorsContainer">
							<h4 class="title">{d.Name}</h4>
							<div class={`slider-${d.Name.replaceAll(' ', '-')}`}>
								<ul style={{ listStyle: 'none', padding: 0 }}>
									{sponsors.map((s, i) => {
										if (s.Category.Name === d.Name) {
											return (
												<li key={i}>
													<div class="content">
														<a href={s.Url} target="_blank">
															<img src={s.Image.url} alt={s.Name} />
														</a>
													</div>
												</li>
											);
										}
									})}
								</ul>
							</div>
						</div>
					);
				})
			)}
		</>
	);
};

render(<Sidebar />, document.getElementById('Sidebar'));
