import { initialize } from '@volenday/sdk';

initialize({ apiKey, environment, token });

require('./initializations.js');
require('./social.js');

if (page) {
	require('./sidebar.js');
}

switch (page) {
	case 'subscribeNow':
		require('./pages/subscribeNow.js');
		break;
	case 'media':
		require('./pages/media.js');
		break;
	case 'nominations':
		require('./pages/nominations.js');
		break;
	case 'registrations':
		require('./pages/registrations.js');
		require('./pages/payment-form.jsx');
		break;
	case 'sponsor':
		require('./pages/sponsor.js');
		break;
	case 'judging':
		require('./pages/judging.js');
		break;
	case 'historyPhoto':
		require('./pages/historyPhotos');
		break;
	case 'about':
		require('./pages/about');
		break;
}
